import './CardsComexComponent.scss';
import './CardsComexComponentMediaQuery.scss';
import React, { useState, useEffect } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useDarkMode } from '../../NavbarComponent/DarkModeContext';
import { CircularProgress, Stack } from '@mui/material';

function CardsComex() {
  const { isDarkMode } = useDarkMode();
  const [barChartData, setBarChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Función para obtener los datos de la API
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.useblip.com/boxesdata');
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();

        setBarChartData(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <Grid className="CardsContainerCard">
      <div className="CardsLineContainer">
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">
              Máscaras importadas - Junio 2024
            </h5>
          </div>
          <div className="CardsMainText">
            <p className="CardsMainTextContent">
              {barChartData.last_month.mascaras}
            </p>
            <div className="CardsSubTextContainer">
              <p className="CardsMainSubTextRight">-</p>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">
              Monitores importados - Junio 2024
            </h5>
          </div>
          <div className="CardsMainText">
            <p className="CardsMainTextContent">
              {barChartData.last_month.monitores}
            </p>
            <div className="CardsSubTextContainer">
              <p className="CardsMainSubTextRight">-</p>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">
              Incubadoras importadas - Junio 2024
            </h5>
          </div>
          <div className="CardsMainText">
            <p className="CardsMainTextContent">
              {barChartData.last_month.incubadoras}
            </p>
            <div className="CardsSubTextContainer">
              <p className="CardsMainSubTextRight">-</p>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
      </div>
      <div className="CardsLineContainer">
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">Máscaras importadas - 2024</h5>
          </div>
          <div className="CardsMainText">
            <p className="CardsMainTextContent">
              {barChartData.current_year.mascaras}
            </p>
            <div className="CardsSubTextContainer">
              <p className="CardsMainSubTextRight">-</p>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">Monitores importados - 2024</h5>
          </div>
          <div className="CardsMainText">
            <p className="CardsMainTextContent">
              {barChartData.current_year.monitores}
            </p>
            <div className="CardsSubTextContainer">
              <p className="CardsMainSubTextRight">-</p>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">Incubadoras importadas - 2024</h5>
          </div>
          <div className="CardsMainText">
            <p className="CardsMainTextContent">
              {barChartData.current_year.incubadoras}
            </p>
            <div className="CardsSubTextContainer">
              <p className="CardsMainSubTextRight">-</p>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default CardsComex;
