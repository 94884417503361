import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import InkDataLogo from '../../Images/InkDataLogo.png';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: '#292c34', color: 'white', paddingY: '40px' }}>
      <Container maxWidth="sm" style={{ textAlign: 'center' }}>
        <img src={InkDataLogo} alt="Inkdata" style={{ height: '75px' }} />
      </Container>
    </Box>
  );
};

export default Footer;
