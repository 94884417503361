/* eslint-disable no-unused-vars */
import './App.css';
import React, { useEffect } from 'react';
import GeneralOverview from './Pages/General/GeneralOverview';
import Comex from './Pages/Comex/Comex';
import Login from './Pages/Login/Login';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { DarkModeProvider } from './Components/NavbarComponent/DarkModeContext';
import { isLoggedIn } from './auth';
import Importer from './Pages/Importer/Importer';

const PrivateRoute = ({ element }) => {
  return isLoggedIn() ? element : <Navigate to="/" />;
};

function App() {
  useEffect(() => {
    document.title = 'Ink Data'; // Set the new title here
  }, []);

  return (
    <DarkModeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={<PrivateRoute element={<Importer />} />}
          />
          <Route path="/demo" element={<PrivateRoute element={<Comex />} />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Router>
    </DarkModeProvider>
  );
}

export default App;
