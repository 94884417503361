/* eslint-disable no-unused-vars */
import React from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import './Importer.scss';
import Navbar from '../../Components/NavbarComponent/NavbarComponent';
// import SegmentsImportsCard from "../../Cards/TECME/SegmentsImportsCard/SegmentsImportsCard"
import CardsImporterCard from '../../Cards/ImporterCards/BoxesCard/BoxesCard';
import BarChartImportsCard from '../../Cards/ImporterCards/BarChartImportsCard/BarChartImportsCard';
import BarChartImportersProductsCard from '../../Cards/ImporterCards/BarChartImportersProductsCard/BarChartImportersProductsCard';
import { useDarkMode } from '../../Components/NavbarComponent/DarkModeContext';
import TableCard from '../../Cards/TableCard/TableCard';
import Footer from '../../Components/Footer/Footer';
import { ImportersProvider } from '../../Components/ImportersProvider';
import AnualImportsByImporter from '../../Components/Imports/AnualImportsByImporter/AnualImportsByImporter';
import ImportsByTransportType from '../../Components/Imports/ImportsByTransportType/ImportsByTransportType';
import ImportsByBrand from '../../Components/Imports/ImportsByBrand/ImportsByBrand';
import IncotermByImporter from '../../Components/Imports/IncotermByImporter/IncotermByImporter';
import AnualImportsByBrand from '../../Components/Imports/AnualImportsByBrand/AnualImportsByBrand';

function Importer() {
  const { isDarkMode } = useDarkMode();
  return (
    <>
      <Navbar />
      <Grid
        className="GridContainer"
        rowSpacing={2}
        columnSpacing={2}
        container
        style={{ backgroundColor: isDarkMode ? '#30333C' : '#F8F9FD' }}
      >
        <ImportersProvider>
          <Grid
            columnSpacing={3}
            rowSpacing={3}
            container
            item
            xlOffset={1}
            xl={10}
            sm={12}
            paddingBottom={8}
          >
            <CardsImporterCard />
            <Grid className="BarChartGridContainer" item xs={12}>
              <AnualImportsByImporter />
            </Grid>
            <BarChartImportsCard />
            <Grid className="BarChartGridContainer" item xs={4}>
              <ImportsByTransportType />
            </Grid>
            <TableCard />
            {/* <Grid className="BarChartGridContainer" item xs={6}>
              <ImportsByBrand />
            </Grid> */}
            {/* <BarChartImportersProductsCard /> */}
            <Grid className="BarChartGridContainer" item xs={8}>
              <AnualImportsByBrand />
            </Grid>
            <Grid className="BarChartGridContainer" item xs={4}>
              <IncotermByImporter />
            </Grid>
          </Grid>
        </ImportersProvider>
      </Grid>
      <Footer />
    </>
  );
}

export default Importer;
