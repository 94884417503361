import React, { createContext, useState, useEffect, useContext } from 'react';
import { getUser } from '../auth';

// Create the context
const ImportersContext = createContext();

// Create a provider component
export const ImportersProvider = ({ children }) => {
  const [importersList, setImportersList] = useState({});
  const user = React.useMemo(() => getUser(), []);

  useEffect(() => {
    const fetchImporters = async () => {
      try {
        const response = await fetch(
          'https://api.useblip.com/importers?company=' + user.company
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        setImportersList(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchImporters();
  }, [user.company]);
  localStorage.setItem('importer', importersList.default);

  return (
    <ImportersContext.Provider value={importersList}>
      {children}
    </ImportersContext.Provider>
  );
};

// Create a custom hook to use the ImportersContext
export const useImporters = () => {
  return useContext(ImportersContext);
};
