import './BarChartImportersImportsNotStackedComponent.scss';
import './BarChartImportersImportsNotStackedComponentMediaQuery.scss';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useDarkMode } from '../../NavbarComponent/DarkModeContext';
import {
  getCartesianGridColor,
  getCartesianAxisColor,
} from '../../GlobalScss/Global';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CircularProgress } from '@mui/material';

function BarChartImportersImportsNotStackedComponent() {
  const { isDarkMode } = useDarkMode();
  const [barChartData, setBarChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNCM, setSelectedNCM] = useState('9018.90.91.100A'); // State to keep track of the selected NCM
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  const availableNCMs = [
    { ncm: '9018.90.91.100A', name: 'Incubadoras' },
    { ncm: '9019.20.10.900C', name: 'Mascaras respiratorias' },
    { ncm: '9018.19.80.100C', name: 'Monitores' },
  ];

  useEffect(() => {
    // Función para obtener los datos de la API
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.useblip.com/importsbyimporternotstacked?ncms=' +
            selectedNCM
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        setBarChartData(data); // Establecer los datos obtenidos en el estado
        setIsLoading(false); // Cambiar el estado de carga a falso
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData(); // Llamar a la función para obtener los datos al montar el componente
  }, [selectedNCM]); // El segundo argumento es un array vacío, lo que significa que este efecto solo se ejecuta una vez al montar el componente

  const filteredData = React.useMemo(() => {
    return barChartData.filter(
      (data) => data.CalendarMonth === selectedYear.toString()
    );
  }, [selectedYear, barChartData]);

  const aggregateSalesByBrand = (data) => {
    const aggregatedData = data.reduce((result, item) => {
      const key = item.Brand;
      if (!result[key]) {
        result[key] = { ...item };
      } else {
        result[key].Unidades += item.Unidades;
      }
      return result;
    }, {});

    const sortedAggregatedData = Object.values(aggregatedData).sort(
      (a, b) => b.Unidades - a.Unidades
    );

    return sortedAggregatedData;
  };
  const truncate = (str) => {
    return str.length > 8 ? str.substring(0, 8) + '...' : str;
  };

  const aggregatedData = aggregateSalesByBrand(filteredData);

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <div className="BarChartWrapper">
      <motion.div
        className={`BarChartComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="80px"
            borderBottom={'1px solid rgba(255, 255, 255, 0.5019607843)'}
          >
            <h5 className="BarChartHeaderTitle">
              Importaciones por importador - anual
            </h5>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 61 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedYear}
                placeholder="Año"
                autoWidth
                onChange={(val) => setSelectedYear(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-input': {
                    borderBottomColor: 'white',
                  },
                }}
              >
                {years.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedNCM}
                placeholder="Ncm"
                onChange={(val) => setSelectedNCM(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-root': {
                    color: 'white',
                  },
                }}
              >
                {availableNCMs.map((ncm) => (
                  <MenuItem value={ncm.ncm}>{ncm.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
        <ResponsiveContainer
          className="BarChartGraphContainer"
          width="100%"
          height="100%"
        >
          <BarChart
            data={aggregatedData}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 90,
            }}
          >
            <CartesianGrid
              stroke={getCartesianGridColor(isDarkMode)}
              vertical={false}
            />
            <XAxis
              style={{ fontSize: '14px' }}
              dataKey="Brand"
              label={{ value: '', position: 'bottom', dy: -8 }}
              stroke={getCartesianAxisColor(isDarkMode)}
              tickFormatter={truncate}
            />
            <YAxis
              // domain={yAxisDomain}
              // ticks={Array.from({ length: maxSalesValue / 1000 + 1 }, (_, index) => index * 1000)}
              label={{
                value: 'Unidades',
                angle: '-90',
                position: 'outsideTopLeft',
                dx: -40,
              }}
              stroke={getCartesianAxisColor(isDarkMode)}
            />
            <Tooltip
              cursor={false}
              offset={40}
              contentStyle={{
                background: '#5b606a',
                borderRadius: '10px',
                border: '0px',
              }}
              labelStyle={{ color: 'White' }}
              itemStyle={{ color: 'white' }}
            />
            <Bar dataKey="Unidades" fill="#6BC0B0" barSize={70} />
          </BarChart>
        </ResponsiveContainer>
      </motion.div>
    </div>
  );
}
export default BarChartImportersImportsNotStackedComponent;
