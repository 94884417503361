import './BoxesCard.scss';
import React from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import CardsComexComponent from '../../../Components/Imports/CardsComexComponent/CardsComexComponent';

function CardsComexCard() {
  return (
    <Grid className="CardsBoxGridContainer" item xs={12}>
      <CardsComexComponent />
    </Grid>
  );
}

export default CardsComexCard;
