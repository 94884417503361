import React from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import './Comex.scss';
import Navbar from '../../Components/NavbarComponent/NavbarComponent';
// import SegmentsImportsCard from "../../Cards/TECME/SegmentsImportsCard/SegmentsImportsCard"
import CardsComexCard from '../../Cards/TECME/CardsComexCard/CardsComexCard';
import BarChartImportsCard from '../../Cards/TECME/BarChartImportsCard/BarChartImportsCard';
import BarChartImportersImportsCard from '../../Cards/TECME/BarChartImportersImportsCard/BarChartImportersImportsCard';
import BarChartImportersProductsCard from '../../Cards/TECME/BarChartImportersProductsCard/BarChartImportersProductsCard';
import PieChartTransportCard from '../../Cards/TECME/PieChartTransportCard/PieChartTransportCard';
import BarChartBrandsImportsCard from '../../Cards/TECME/BarChartBrandsImportsCard/BarChartBrandsImportsCard';
import BarChartBrandsImportsNotStackedCard from '../../Cards/TECME/BarChartBrandsImportsNotStackedCard/BarChartBrandsImportsNotStackedCard';
import BarChartImportersImportsNotStackedCard from '../../Cards/TECME/BarChartImportersImportsNotStackedCard/BarChartImportersImportsNotStackedCard';
import { useDarkMode } from '../../Components/NavbarComponent/DarkModeContext';
import TableCard from '../../Cards/TableCard/TableCard';
import Footer from '../../Components/Footer/Footer';

function Comex() {
  const { isDarkMode } = useDarkMode();
  return (
    <>
      <Navbar />
      <Grid
        className="GridContainer"
        rowSpacing={2}
        columnSpacing={2}
        container
        style={{ backgroundColor: isDarkMode ? '#30333C' : '#F8F9FD' }}
      >
        <Grid container item xlOffset={1} xl={10} sm={12} paddingBottom={8}>
          <CardsComexCard />
          <BarChartImportsCard />
          <PieChartTransportCard />
          <BarChartImportersProductsCard />
          <BarChartImportersImportsCard />
          {/* <SegmentsImportsCard /> */}
          <BarChartBrandsImportsCard />
          <TableCard />
          <BarChartBrandsImportsNotStackedCard />
          <BarChartImportersImportsNotStackedCard />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default Comex;
