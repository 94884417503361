import React, { useState } from 'react';
import './Login.scss';
import './LoginMediaQuery.scss';
import { useNavigate } from 'react-router-dom';
import ParticlesBackground from './ParticlesBackground';
import { CircularProgress, Stack } from '@mui/material';
import { saveUser } from '../../auth';

const users = [
  {
    username: 'garni',
    password: 'adminGarni',
    company: 'garni',
  },
  {
    username: 'goodies',
    password: 'adminGoodies',
    company: 'goodies',
  },
  {
    username: 'loza',
    password: 'adminLoza',
    company: 'loza',
  },
  {
    username: 'ankasdelsur',
    password: 'adminAnkasdelsur',
    company: 'ankasdelsur',
  },
  {
    username: 'tecmaq',
    password: 'adminTecmaq',
    company: 'tecmaq',
  },
  {
    username: 'fiesa',
    password: 'adminFiesa',
    company: 'fiesa',
  },
  {
    username: 'argentaurus',
    password: 'argentaurus',
    company: 'argentaurus',
  },
  {
    username: 'snaider1',
    password: 'adminSnaider1',
    company: 'snaider1',
  },
];

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    const user = users.find(
      (u) => u.username === username && u.password === password
    );
    if (user) {
      setIsLoading(true);
      await saveUser(user);
      navigate(`/home`);
    } else {
      setErrorMessage('Usuario o contraseña incorrectos');
      console.log('Login failed');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // If the Enter key is pressed, simulate a click on the login button
      handleLogin();
    }
  };

  return (
    <div className="LoginContainer">
      <ParticlesBackground />
      <div className="LoginCard">
        <div className="LoginTextWrapper">
          <div className="LoginInkData">
            <div className="LoginLogo"></div>
            <div className="LoginName">Ink Data</div>
          </div>
          <div className="LoginInputLabelContainer">
            <label className="LoginLabelUsername LoginLabel">Username</label>
            <input
              className="LoginInputUsername LoginInput"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="LoginInputLabelContainer">
            <label className="LoginLabelPassword LoginLabel">Password</label>
            <input
              className="LoginInputPassword LoginInput"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress} // Listen for Enter key press
            />
          </div>
          <button className="LoginButton" onClick={handleLogin}>
            Login
          </button>
          {isLoading && (
            <Stack justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          )}
          {errorMessage && <div className="ErrorMessage">{errorMessage}</div>}
        </div>
      </div>
    </div>
  );
}

export default Login;
