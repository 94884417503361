import './AnualImportsByImporterMediaQuery.scss';
import './AnualImportsByImporter.scss';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import SquareIcon from '@mui/icons-material/Square';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { useDarkMode } from '../../NavbarComponent/DarkModeContext';
import {
  getCartesianGridColor,
  getCartesianAxisColor,
} from '../../GlobalScss/Global';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { formatNumber, getDistinctColor } from '../../../Utils/utils';
import { getUser } from '../../../auth';

const capDataAndAddOthers = (data, maxBars = 15) => {
  const sortedData = data.sort((a, b) => b.totalFOB - a.totalFOB);
  const topBrands = sortedData.slice(0, maxBars);

  const others = sortedData.slice(maxBars).reduce(
    (acc, item) => {
      acc.totalFOB += item.totalFOB;
      return acc;
    },
    { importer: 'Others', totalFOB: 0 }
  );

  return others.totalFOB > 0 ? [...topBrands, others] : topBrands;
};

const AnualImportsByImporter = () => {
  const { isDarkMode } = useDarkMode();
  const user = React.useMemo(() => getUser(), []);
  const [isLoading, setIsLoading] = useState(true);
  const [rawData, setRawData] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.useblip.com/annual-imports?company=' +
            user.company +
            '&year=' +
            selectedYear
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        setRawData(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [user.company, selectedYear]);

  const chartData = capDataAndAddOthers(rawData);
  // Measures the current size of the screen
  const isScreenSmall = useMediaQuery(
    '(min-width: 350px) and (max-width: 450px)'
  );

  const formatYAxis = (value) => {
    const formatter = (val, suffix) => {
      const formatted = val.toFixed(1);
      return formatted.endsWith('.0')
        ? `${parseInt(formatted)}${suffix}`
        : `${formatted}${suffix}`;
    };

    if (value >= 1000000) {
      return formatter(value / 1000000, 'M');
    } else if (value >= 1000) {
      return formatter(value / 1000, 'K');
    }
    return value.toString();
  };

  let importers = new Set();
  for (const data in chartData) {
    importers.add(chartData[data].importer);
  }

  const formatXAxis = (value) => {
    if (importers.size > 7) {
      return value.length > 7 ? `${value.substring(0, 7)}...` : value;
    }
    return value;
  };

  // Margins for barchart graph depending on the
  const rightMargin = isScreenSmall ? 15 : 30;
  const leftMargin = isScreenSmall ? -10 : 20;
  const bottomMargin = isScreenSmall ? 75 : 90;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            background: '#5b606a',
            borderRadius: '10px',
            border: '0px',
            padding: '15px',
          }}
        >
          <Stack direction="column" alignItems="left" marginTop="4px">
            <Typography>{data.importer}</Typography>
            <Stack direction="row" alignItems="center">
              <SquareIcon
                sx={{
                  color: payload[0].fill,
                  fontSize: '16px',
                  marginRight: '4px',
                }}
              />
              <Typography variant="body1">
                Total [FOB]: ${formatNumber(data.totalFOB)} USD
              </Typography>
            </Stack>
          </Stack>
        </div>
      );
    }

    return null;
  };
  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  const totalFOB = chartData.reduce((sum, data) => sum + data.totalFOB, 0);
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${((value / totalFOB) * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };
  return (
    <div className="BarChartWrapper">
      <motion.div
        className={`BarChartComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="80px"
            borderBottom={'1px solid rgba(255, 255, 255, 0.5019607843)'}
          >
            <h5 className="BarChartHeaderTitle">
              Importaciones anuales - FOB USD
            </h5>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 61 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedYear}
                placeholder="Año"
                onChange={(val) => setSelectedYear(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-input': {
                    borderBottomColor: 'white',
                  },
                }}
              >
                {years.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
        {chartData.length === 0 && (
          <Stack height="100%" justifyContent="center" alignItems="center">
            <Typography variant="subtitle1">
              No hay datos que cumplan con los filtros aplicados
            </Typography>
            <Typography variant="body2">
              Por favor refina tu búsqueda
            </Typography>
          </Stack>
        )}
        {chartData.length > 0 && (
          <ResponsiveContainer style={{ padding: '20px' }} width="100%">
            <BarChart
              data={chartData} // Use the new data structure
              margin={{
                top: 20,
                right: rightMargin,
                left: leftMargin,
                bottom: bottomMargin,
              }}
            >
              <CartesianGrid
                stroke={getCartesianGridColor(isDarkMode)}
                vertical={false}
              />
              <XAxis
                label={{ value: 'Importador', position: 'bottom', dy: -8 }}
                stroke={getCartesianAxisColor(isDarkMode)}
                dataKey="importer"
                tickFormatter={formatXAxis}
              />
              <YAxis
                label={{
                  value: '',
                  angle: '-90',
                  position: 'outsideTopLeft',
                  dx: -40,
                }}
                stroke={getCartesianAxisColor(isDarkMode)}
                dataKey="totalFOB"
                tickFormatter={formatYAxis}
              />
              <Tooltip cursor={false} offset={40} content={<CustomTooltip />} />
              <Bar
                dataKey="totalFOB"
                fill={getDistinctColor(0, 'totalFOB')}
                maxBarSize={80}
              >
                <LabelList
                  dataKey="totalFOB"
                  position="top"
                  content={renderCustomizedLabel}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </motion.div>
    </div>
  );
};

export default AnualImportsByImporter;
