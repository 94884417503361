import './BarChartImportersImportsComponentMediaQuery.scss';
import './BarChartImportersImportsComponent.scss';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  BarChart,
  Legend,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { useDarkMode } from '../../NavbarComponent/DarkModeContext';
import {
  getCartesianGridColor,
  getCartesianAxisColor,
  getPieChartLabelsColor,
} from '../../GlobalScss/Global';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const getAllKeys = (data) => {
  const allKeys = new Set();
  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'CalendarMonth') {
        allKeys.add(key);
      }
    });
  });
  return Array.from(allKeys);
};

function BarChartBrandsImportsComponent() {
  const { isDarkMode } = useDarkMode();
  const [isLoading, setIsLoading] = useState(true);
  const [BarChartImportersDataTecme, setBarChartImportersDataTecme] = useState(
    []
  );
  const [selectedNCM, setSelectedNCM] = useState('9018.90.91.100A');
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  const availableNCMs = [
    { ncm: '9018.90.91.100A', name: 'Incubadoras' },
    { ncm: '9019.20.10.900C', name: 'Mascaras respiratorias' },
    { ncm: '9018.19.80.100C', name: 'Monitores' },
  ];

  useEffect(() => {
    // Función para obtener los datos de la API
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.useblip.com/importsbyimporter?ncms=' + selectedNCM
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        setBarChartImportersDataTecme(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, [selectedNCM]);

  const stackedBarChartData = BarChartImportersDataTecme.map((entry) => {
    // Create a new entry with sorted values for each bar segment
    const sortedEntry = {
      CalendarMonth: entry.CalendarMonth,
    };

    // Sort the values for each bar segment in descending order
    Object.entries(entry)
      .filter(([key]) => key !== 'CalendarMonth')
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => b.value - a.value)
      .forEach(({ key, value }) => {
        sortedEntry[key] = value;
      });

    return sortedEntry;
  });

  const sortedBarChartData = stackedBarChartData.sort((a, b) =>
    a.CalendarMonth.localeCompare(b.CalendarMonth)
  );
  const filteredData = sortedBarChartData.filter((data) =>
    data.CalendarMonth.startsWith(selectedYear)
  );

  const isScreenSmall = useMediaQuery(
    '(min-width: 350px) and (max-width: 450px)'
  );

  const topMargin = isScreenSmall ? 5 : 10;
  const rightMargin = isScreenSmall ? 15 : 30;
  const leftMargin = isScreenSmall ? -10 : 20;
  const bottomMargin = isScreenSmall ? 75 : 90;
  const legendIconSize = isScreenSmall ? 0 : 12;
  const legendMarginTop = isScreenSmall ? 200 : -422;

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul
        className="legendUl"
        style={{ color: getPieChartLabelsColor(isDarkMode) }}
      >
        {payload.map((entry, index) => (
          <li className="legendLi" key={`item-${index}`}>
            <span className="legendIcon">
              <svg height={10} width={30}>
                <line
                  x1="7"
                  y1="5"
                  x2="25"
                  y2="5"
                  stroke={entry.color}
                  strokeWidth="2"
                />
              </svg>
            </span>
            <span className="legendSpan">{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div
          className="custom-tooltip"
          style={{
            background: '#5b606a',
            borderRadius: '10px',
            border: '0px',
            padding: '15px',
          }}
        >
          <p style={{ fontSize: '16px', color: 'white', fontWeight: 'bolder' }}>
            {data.CalendarMonth}
          </p>
          {Object.entries(data)
            .filter(([key]) => key !== 'CalendarMonth')
            .map(([key, value]) => (
              <p
                key={key}
                style={{ fontSize: '14px', color: 'white', marginTop: '7px' }}
              >{`${key}: ${value - (data[key - 1] || 0)}`}</p>
            ))}
        </div>
      );
    }

    return null;
  };

  const getDistinctColor = (index, key) => {
    // Ensure Tecme is always blue
    if (key === 'Tecme') {
      return 'rgb(24, 160, 251)';
    }
    if (key === 'DCD') {
      return '#6BC0B0';
    }

    const colors = [
      '#E8AAE7',
      '#EE7C6E',
      '#F8D849',
      '#18A0FB',
      '#964B00',
      '#8E58B1',
      '#bc6c25',
      '#a2d2ff',
      '#BC8F8F',
      '#669bbc',
      '#A52A2A',
      '#f2a65a',
      '#a5a58d',
      '#ced4da',
      '#D4B483',
      '#A0522D',
    ];
    // Ensure that the index wraps around if it goes beyond the length of the colors array
    const wrappedIndex = index % colors.length;

    return colors[wrappedIndex];
  };
  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <div className="BarChartWrapper">
      <motion.div
        className={`BarChartComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="80px"
            borderBottom={'1px solid rgba(255, 255, 255, 0.5019607843)'}
          >
            <h5 className="BarChartHeaderTitle">
              Importaciones por importador - mensual
            </h5>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 61 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedYear}
                placeholder="Año"
                autoWidth
                onChange={(val) => setSelectedYear(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-input': {
                    borderBottomColor: 'white',
                  },
                }}
              >
                {years.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedNCM}
                placeholder="Ncm"
                onChange={(val) => setSelectedNCM(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-root': {
                    color: 'white',
                  },
                }}
              >
                {availableNCMs.map((ncm) => (
                  <MenuItem value={ncm.ncm}>{ncm.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
        <ResponsiveContainer
          className="BarChartGraphContainer"
          width="100%"
          height="100%"
        >
          <BarChart
            data={filteredData}
            margin={{
              top: topMargin,
              right: rightMargin,
              left: leftMargin,
              bottom: bottomMargin,
            }}
          >
            <CartesianGrid
              stroke={getCartesianGridColor(isDarkMode)}
              vertical={false}
            />
            <XAxis
              label={{ value: 'Mes', position: 'bottom', dy: -8 }}
              stroke={getCartesianAxisColor(isDarkMode)}
              dataKey="CalendarMonth"
              tickFormatter={(value) => value.slice(5)}
            />
            <YAxis
              label={{
                value: 'Unidades',
                angle: '-90',
                position: 'outsideTopLeft',
                dx: -40,
              }}
              stroke={getCartesianAxisColor(isDarkMode)}
            />
            <Tooltip cursor={false} offset={40} content={<CustomTooltip />} />
            <Legend
              iconType="square"
              iconSize={legendIconSize}
              wrapperStyle={{ marginTop: legendMarginTop }}
              verticalAlign="bottom"
              content={renderLegend}
            />
            {filteredData.length > 0 &&
              getAllKeys(filteredData)
                .filter((key) => key !== 'CalendarMonth')
                .map((key, index) => (
                  <Bar
                    key={index}
                    dataKey={key}
                    fill={getDistinctColor(index, key)}
                    stackId="1"
                  />
                ))}
          </BarChart>
        </ResponsiveContainer>
      </motion.div>
    </div>
  );
}

export default BarChartBrandsImportsComponent;
