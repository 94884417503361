import './TableCard.scss';
import React from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import TableComponent from '../../Components/TableComponent/TableComponent';

const TableCard = () => {
  return (
    <>
      <div
        className="DoubleClickImage"
        id="notification"
        style={{
          display: 'none',
          position: 'fixed',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#30333C',
          color: '#77D5D7',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 10,
        }}
      >
        Copied!
      </div>
      <Grid
        className="TableGridContainer"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <TableComponent />
      </Grid>
    </>
  );
};

export default TableCard;
