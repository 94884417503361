/* eslint-disable no-unused-vars */
import './NavbarComponent.scss';
import './NavbarComponentMediaQuery.scss';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDarkMode } from './DarkModeContext';
import { getUser } from '../../auth';

function Navbar() {
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  // Use the useEffect hook to apply the dark mode to the entire page
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  return (
    <div
      className="NavbarContainer"
      style={{ backgroundColor: isDarkMode ? '#30333C' : '#F8F9FD' }}
    >
      <div
        className="NavbarInkData"
        style={{
          backgroundColor: isDarkMode
            ? 'var(--dm-nav-fixed-background, #292C34)'
            : '#477878',
        }}
      >
        <div className="NavbarInkDataLeft">
          <div
            className={`InkDataLogo ${isDarkMode ? 'InkDataLogoDark' : 'InkDataLogoLight'}`}
          ></div>
          <p
            className="InkDataTitle"
            style={{ color: isDarkMode ? '#6BC0B0' : 'white' }}
          >
            Ink Data
          </p>
        </div>
      </div>
      <div
        className="NavbarMainContainer"
        style={{ backgroundColor: isDarkMode ? '#30333C' : '#F8F9FD' }}
      >
        <div className="NavbarProfile">
          <div className="NavbarProfileGreetings">
            <h4
              className="NavbarProfileGreetingsTitle"
              style={{ color: isDarkMode ? 'white' : '#20304A' }}
            >
              Bienvenido
            </h4>
            <p
              className="NavbarProfileGreetingsCompanyName"
              style={{ color: isDarkMode ? 'white' : '#20304A' }}
            >
              {getUser().company.toUpperCase()}
            </p>
            <p
              className="NavbarProfileGreetingsSubtitle"
              style={{ color: isDarkMode ? 'white' : '#20304A' }}
            ></p>
          </div>
        </div>
        <div className="NavbarModulesContainer">
          <div
            className={`${isDarkMode ? 'NavbarModulesListDark' : 'NavbarModulesListLight'}`}
            style={{ backgroundColor: isDarkMode ? '#373C47' : '#477878' }}
          >
            <Link
              to="/home"
              className={`ModuleLi ${location.pathname === '/home' ? 'active' : ''} ${isDarkMode ? 'ModuleLi' : 'ModuleLiLight'}`}
            >
              Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
