import './BarChartImportsComponentMediaQuery.scss';
import './BarChartImportsComponent.scss';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  BarChart,
  Legend,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { useDarkMode } from '../../NavbarComponent/DarkModeContext';
import {
  getCartesianGridColor,
  getCartesianAxisColor,
  getPieChartLabelsColor,
} from '../../GlobalScss/Global';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function BarChartImportsComponent() {
  const { isDarkMode } = useDarkMode();
  const [isLoading, setIsLoading] = useState(true);
  const [barChartDataTecme, setBarChartDataTecme] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  useEffect(() => {
    // Función para obtener los datos de la API
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.useblip.com/importsbyncm?ncms=9018.90.91.100A,9019.20.10.900C,9018.19.80.100C'
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        setBarChartDataTecme(data); // Establecer los datos obtenidos en el estado
        setIsLoading(false); // Cambiar el estado de carga a falso
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData(); // Llamar a la función para obtener los datos al montar el componente
  }, []);

  // Calculate cumulative values for each category
  // const stackedBarChartData = barChartDataTecme.map((entry) => {
  //   let cumulativeValue = 0;
  //   return Object.keys(entry).reduce((acc, key) => {
  //     if (key !== 'CalendarMonth') {
  //       cumulativeValue += entry[key];
  //       acc[key] = cumulativeValue;
  //     } else {
  //       acc[key] = entry[key];
  //     }
  //     return acc;
  //   }, {});
  // });

  // Sorting the BarChartData based on CalendarMonth in chronological order
  const sortedBarChartData = barChartDataTecme.sort((a, b) =>
    a.CalendarMonth.localeCompare(b.CalendarMonth)
  );

  // Filter the data based on the selected year
  const filteredData = sortedBarChartData.filter((data) =>
    data.CalendarMonth.startsWith(selectedYear.toString())
  );

  // Measures the current size of the screen
  const isScreenSmall = useMediaQuery(
    '(min-width: 350px) and (max-width: 450px)'
  );

  // Margins for barchart graph depending on the
  const topMargin = isScreenSmall ? 5 : 10;
  const rightMargin = isScreenSmall ? 15 : 30;
  const leftMargin = isScreenSmall ? -10 : 20;
  const bottomMargin = isScreenSmall ? 75 : 90;
  const legendIconSize = isScreenSmall ? 0 : 12;
  const legendMarginTop = isScreenSmall ? 200 : -422;
  // XS DEVICES SCREEN LOGIC END

  // LEGEND CUSTOM FUNCTION

  // Define a custom function to render the legend content
  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul
        className="legendUl"
        style={{ color: getPieChartLabelsColor(isDarkMode) }}
      >
        {payload.map((entry, index) => (
          <li className="legendLi" key={`item-${index}`}>
            <span className="legendIcon">
              <svg height={10} width={30}>
                <line
                  x1="7"
                  y1="5"
                  x2="25"
                  y2="5"
                  stroke={entry.color}
                  strokeWidth="2"
                />
              </svg>
            </span>
            <span className="legendSpan">{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  // LEGEND CUSTOM FUNCTION END

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      // Extract and sort data values in descending order
      const sortedData = Object.entries(data)
        .filter(([key]) => key !== 'CalendarMonth')
        .sort((a, b) => b[1] - a[1]);

      return (
        <div
          className="custom-tooltip"
          style={{
            background: '#5b606a',
            borderRadius: '10px',
            border: '0px',
            padding: '15px',
          }}
        >
          <p style={{ color: 'white', fontWeight: 'bolder' }}>
            {data.CalendarMonth}
          </p>
          {sortedData.map(([key, value]) => (
            <p
              key={key}
              style={{ color: 'white', marginTop: '7px' }}
            >{`${key}: ${value}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };
  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <div className="BarChartWrapper">
      <motion.div
        className={`BarChartComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="80px"
            borderBottom={'1px solid rgba(255, 255, 255, 0.5019607843)'}
          >
            <h5 className="BarChartHeaderTitle">Importaciones por NCM</h5>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 61 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedYear}
                placeholder="Año"
                onChange={(val) => setSelectedYear(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-input': {
                    borderBottomColor: 'white',
                  },
                }}
              >
                {years.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
        <ResponsiveContainer
          className="BarChartGraphContainer"
          width="100%"
          height="100%"
        >
          <BarChart
            data={filteredData} // Use the new data structure
            margin={{
              top: topMargin,
              right: rightMargin,
              left: leftMargin,
              bottom: bottomMargin,
            }}
          >
            <CartesianGrid
              stroke={getCartesianGridColor(isDarkMode)}
              vertical={false}
            />
            <XAxis
              label={{ value: 'Mes', position: 'bottom', dy: -8 }}
              stroke={getCartesianAxisColor(isDarkMode)}
              dataKey="CalendarMonth"
              tickFormatter={(value) => value.slice(5)} // Extract MM from AAAA-MM
            />
            <YAxis
              label={{
                value: 'Unidades',
                angle: '-90',
                position: 'outsideTopLeft',
                dx: -40,
              }}
              stroke={getCartesianAxisColor(isDarkMode)}
            />
            <Tooltip cursor={false} offset={40} content={<CustomTooltip />} />
            <Legend
              iconType="square"
              iconSize={legendIconSize}
              wrapperStyle={{ marginTop: legendMarginTop }}
              verticalAlign="bottom"
              content={renderLegend} // Set the custom legend content
            />
            <Bar
              dataKey="Mascaras"
              fill="#3F8980"
              stackId="1" // Add stackId prop for stacking
            />
            <Bar
              dataKey="Monitores"
              fill="#6BC0B0"
              stackId="1" // Add stackId prop for stacking
            />
            <Bar
              dataKey="Incubadoras"
              fill="#8FDAD0"
              stackId="1" // Add stackId prop for stacking
            />
          </BarChart>
        </ResponsiveContainer>
      </motion.div>
    </div>
  );
}

export default BarChartImportsComponent;
