import './PieChartTransportComponent.scss';
import './PieChartTransportMediaQueryComponent.scss';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Legend,
  Label,
} from 'recharts';
import { useDarkMode } from '../../NavbarComponent/DarkModeContext';
import { getPieChartLabelsColor } from '../../GlobalScss/Global';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function PieChartTransportComponent() {
  const { isDarkMode } = useDarkMode();
  const [isLoading, setIsLoading] = useState(true);
  const [transportData, setTransportData] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    // Función para obtener los datos de la API
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.useblip.com/transport-imports?ncms=9018.90.91.100A,9019.20.10.900C,9018.19.80.100C'
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        setTransportData(data); // Establecer los datos obtenidos en el estado
        setIsLoading(false); // Cambiar el estado de carga a falso
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData(); // Llamar a la función para obtener los datos al montar el componente
  }, []);

  // Data colors
  const Colors = [
    '#E8AAE7',
    '#EE7C6E',
    '#F8D849',
    '#18A0FB',
    '#964B00',
    '#8E58B1',
    '#bc6c25',
    '#a2d2ff',
    '#BC8F8F',
    '#669bbc',
    '#A52A2A',
    '#f2a65a',
    '#a5a58d',
    '#ced4da',
    '#D4B483',
    '#A0522D',
  ];
  const Radian = Math.PI / 180;

  // Custom Label
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
    const x = cx + radius * Math.cos(-midAngle * Radian);
    const y = cy + radius * Math.sin(-midAngle * Radian);

    return (
      <text
        x={x}
        y={y}
        fill={getPieChartLabelsColor(isDarkMode)}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // PIECHART YEARS DISPLAY LOGIC

  // Sorting the PieChartData based on year in chronological order
  const sortedPieChartData = React.useMemo(
    () =>
      transportData
        .sort((a, b) => a.year - b.year)
        .filter((data) => data.year === selectedYear),
    [transportData, selectedYear]
  );

  const theme = useTheme(); // Use useTheme hook to access the theme

  // Use useMediaQuery to check screen size and set outerRadius and innerRadius accordingly
  const is350To450 = useMediaQuery(theme.breakpoints.between(350, 451));
  const is451To600 = useMediaQuery(theme.breakpoints.between(451, 600));
  const is601To750 = useMediaQuery(theme.breakpoints.between(601, 750));
  const is751To900 = useMediaQuery(theme.breakpoints.between(751, 900));
  const is901To1050 = useMediaQuery(theme.breakpoints.between(901, 1050));
  const is1051To1200 = useMediaQuery(theme.breakpoints.between(1051, 1200));
  const is1201To1350 = useMediaQuery(theme.breakpoints.between(1201, 1350));
  const is1351To1500 = useMediaQuery(theme.breakpoints.between(1351, 1500));

  // SCREEN SIZES MEDIA QUERY END

  // PIECHART MEDIA QUERY VARIABLES FOR DIFFERENT SCREEN SIZES

  // Calculate the outerRadius and innerRadius based on the screen width
  const outerRadiusValue = is350To450
    ? 95
    : is451To600
      ? 80
      : is601To750
        ? 90
        : is751To900
          ? 100
          : is901To1050
            ? 90
            : is1051To1200
              ? 115
              : is1201To1350
                ? 130
                : is1351To1500
                  ? 130
                  : 130;
  const innerRadiusValue = is350To450
    ? 80
    : is451To600
      ? 60
      : is601To750
        ? 70
        : is751To900
          ? 80
          : is901To1050
            ? 70
            : is1051To1200
              ? 90
              : is1201To1350
                ? 100
                : is1351To1500
                  ? 100
                  : 100;

  // Calculate Legend variables based on the screen width
  const legendFontSize = is350To450
    ? 14
    : is451To600
      ? 10
      : is601To750
        ? 12
        : is751To900
          ? 14
          : is901To1050
            ? 16
            : is1051To1200
              ? 17
              : is1201To1350
                ? 15
                : is1351To1500
                  ? 16
                  : 16;
  const legendHeight = is350To450
    ? '25%'
    : is451To600
      ? '10%'
      : is601To750
        ? '12%'
        : is751To900
          ? '14%'
          : is901To1050
            ? '26%'
            : is1051To1200
              ? '26%'
              : is1201To1350
                ? '26%'
                : is1351To1500
                  ? '26%'
                  : '25%';
  const legendVerticalAlign = is350To450
    ? 'bottom'
    : is451To600
      ? 'middle'
      : is601To750
        ? 'middle'
        : is751To900
          ? 'middle'
          : is901To1050
            ? 'bottom'
            : is1051To1200
              ? 'bottom'
              : is1201To1350
                ? 'middle'
                : is1351To1500
                  ? 'middle'
                  : 'middle';
  const legendIconSize = is350To450
    ? '8px'
    : is451To600
      ? '8px'
      : is601To750
        ? '8px'
        : is751To900
          ? '8px'
          : is901To1050
            ? '8px'
            : is1051To1200
              ? '8px'
              : is1201To1350
                ? '8px'
                : is1351To1500
                  ? '8px'
                  : '8px';

  // Calculate Pue cx and cy percetages based on the screen width
  const Piecy = is350To450
    ? '47%'
    : is451To600
      ? '10%'
      : is601To750
        ? '12%'
        : is751To900
          ? '14%'
          : is901To1050
            ? '50%'
            : is1051To1200
              ? '48%'
              : is1201To1350
                ? '35%'
                : is1351To1500
                  ? '35%'
                  : '35%';

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <div className="PieChartWrapper">
      <motion.div
        className={`PieChartComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="80px"
            borderBottom={'1px solid rgba(255, 255, 255, 0.5019607843)'}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: 'inherit', marginLeft: '2%', fontWeight: 600 }}
            >
              Vía de transporte
            </Typography>
            <FormControl variant="standard" sx={{ m: 1 }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedYear}
                placeholder="Año"
                autoWidth
                onChange={(val) => setSelectedYear(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-input': {
                    borderBottomColor: 'white',
                  },
                }}
              >
                {years.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
        <ResponsiveContainer
          className="PieChartGraphContainer"
          width="100%"
          height="100%"
        >
          <PieChart>
            {sortedPieChartData.length > 0 ? (
              <Pie
                data={sortedPieChartData}
                cx="50%"
                cy={Piecy}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={outerRadiusValue}
                innerRadius={innerRadiusValue}
                dataKey="value"
                stroke="none"
              >
                {transportData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={Colors[index % Colors.length]}
                  />
                ))}
              </Pie>
            ) : (
              <Pie
                data={[{ name: 'No Data', value: 1 }]}
                cx="50%"
                cy={Piecy}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={outerRadiusValue}
                innerRadius={innerRadiusValue}
                dataKey="value"
                stroke="none"
              />
            )}
            <Legend
              wrapperStyle={{ fontSize: legendFontSize }}
              height={legendHeight}
              align="center"
              verticalAlign={legendVerticalAlign}
              iconType="circle"
              layout="horizontal"
              formatter={(value, entry, index) => (
                <span style={{ color: getPieChartLabelsColor(isDarkMode) }}>
                  {value}
                </span>
              )}
              iconSize={legendIconSize}
            />
            <Label />
            {/* <Tooltip contentStyle={{background: '#5b606a', borderRadius:'10px', border: '0px'}} itemStyle={{color:'white'}}/> */}
          </PieChart>
        </ResponsiveContainer>
      </motion.div>
    </div>
  );
}

export default PieChartTransportComponent;
