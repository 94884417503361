import './CardsComexCard.scss';
import React from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import CardsComexComponent from '../../../Components/TECME/CardsComexComponent/CardsComexComponent';

function CardsComexCard() {
  return (
    <Grid
      className="CardsGridContainerComex"
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <CardsComexComponent />
    </Grid>
  );
}

export default CardsComexCard;
