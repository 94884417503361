// CHARTS VARIABLES

// GRID LINE COLORS
export const getCartesianGridColor = (isDarkMode) => {
  return isDarkMode ? '#ffffff80' : '#b8b8b8';
};

// X-AXIS & Y-AXIS COLORS
export const getCartesianAxisColor = (isDarkMode) => {
  return isDarkMode ? '#ffffff80' : '#7D7D7D';
};

// PIE CHART LABELS
export const getPieChartLabelsColor = (isDarkMode) => {
  return isDarkMode ? '#ffffff80' : '#7D7D7D';
};
