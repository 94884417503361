import './BarChartImportsComponentMediaQuery.scss';
import './BarChartImportsComponent.scss';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { useDarkMode } from '../../NavbarComponent/DarkModeContext';
import {
  getCartesianGridColor,
  getCartesianAxisColor,
} from '../../GlobalScss/Global';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SquareIcon from '@mui/icons-material/Square';
import { useImporters } from '../../ImportersProvider';
import { formatNumber } from '../../../Utils/utils';
import { getUser } from '../../../auth';

function BarChartImportsComponent() {
  const { isDarkMode } = useDarkMode();
  const user = React.useMemo(() => getUser(), []);
  const importersList = useImporters();
  const [isLoading, setIsLoading] = useState(true);
  const [barChartDataTecme, setBarChartDataTecme] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedImporter, setSelectedImporter] = useState(
    importersList.default
  );

  useEffect(() => {
    setSelectedImporter(importersList.default);
  }, [importersList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedImporter) {
          const response = await fetch(
            'https://api.useblip.com/monthly-imports?company=' +
              user.company +
              '&importer=' +
              selectedImporter
          );
          if (!response.ok) {
            throw new Error('Error al obtener los datos');
          }
          const data = await response.json();
          setBarChartDataTecme(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [selectedImporter, user.company]);

  // Sorting the BarChartData based on CalendarMonth in chronological order
  const sortedBarChartData = barChartDataTecme.sort((a, b) =>
    a.CalendarMonth.localeCompare(b.CalendarMonth)
  );

  // Filter the data based on the selected year
  const filteredData = sortedBarChartData.filter((data) =>
    data.CalendarMonth.startsWith(selectedYear.toString())
  );

  // Measures the current size of the screen
  const isScreenSmall = useMediaQuery(
    '(min-width: 350px) and (max-width: 450px)'
  );

  // Margins for barchart graph depending on the
  const topMargin = isScreenSmall ? 5 : 10;
  const rightMargin = isScreenSmall ? 15 : 30;
  const leftMargin = isScreenSmall ? -10 : 20;
  const bottomMargin = isScreenSmall ? 75 : 90;

  const formatYAxis = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      // Extract and sort data values in descending order
      const sortedData = Object.entries(data)
        .filter(([key]) => key !== 'CalendarMonth')
        .sort((a, b) => b[1] - a[1]);

      return (
        <div
          className="custom-tooltip"
          style={{
            background: '#5b606a',
            borderRadius: '10px',
            border: '0px',
            padding: '15px',
          }}
        >
          <p style={{ color: 'white', fontWeight: 'bolder' }}>
            {data.CalendarMonth}
          </p>
          {sortedData.map(([key, value]) => (
            <Stack direction="row" alignItems="center" marginTop="4px">
              <SquareIcon
                sx={{
                  color: payload.find((item) => item.dataKey === key).fill,
                  fontSize: '16px',
                  marginRight: '4px',
                }}
              />
              <p key={key} style={{ color: 'white' }}>
                Total [FOB]: ${formatNumber(value)} USD
              </p>
            </Stack>
          ))}
        </div>
      );
    }

    return null;
  };
  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <div className="BarChartWrapper">
      <motion.div
        className={`BarChartComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="80px"
            borderBottom={'1px solid rgba(255, 255, 255, 0.5019607843)'}
          >
            <h5 className="BarChartHeaderTitle">
              Importaciones mensuales - FOB USD
            </h5>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 61 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedYear}
                placeholder="Año"
                onChange={(val) => setSelectedYear(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-input': {
                    borderBottomColor: 'white',
                  },
                }}
              >
                {years.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedImporter}
                isLoading={importersList}
                placeholder="Ncm"
                onChange={(val) => setSelectedImporter(val.target.value)}
                sx={{
                  color: 'white',
                  '$ .MuiInputBase-root': {
                    color: 'white',
                  },
                }}
              >
                {importersList &&
                  importersList.importers &&
                  importersList.importers.length > 0 &&
                  importersList.importers.map((importer) => (
                    <MenuItem value={importer}>{importer}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
        {filteredData.length === 0 && (
          <Stack height="100%" justifyContent="center" alignItems="center">
            <Typography variant="subtitle1">
              No hay datos que cumplan con los filtros aplicados
            </Typography>
            <Typography variant="body2">
              Por favor refina tu búsqueda
            </Typography>
          </Stack>
        )}
        {filteredData.length > 0 && (
          <ResponsiveContainer style={{ padding: '20px' }}>
            <BarChart
              data={filteredData} // Use the new data structure
              margin={{
                top: topMargin,
                right: rightMargin,
                left: leftMargin,
                bottom: bottomMargin,
              }}
            >
              <CartesianGrid
                stroke={getCartesianGridColor(isDarkMode)}
                vertical={false}
              />
              <XAxis
                label={{ value: 'Mes', position: 'bottom', dy: -8 }}
                stroke={getCartesianAxisColor(isDarkMode)}
                dataKey="CalendarMonth"
                tickFormatter={(value) => value.slice(5)} // Extract MM from AAAA-MM
              />
              <YAxis
                label={{
                  value: '',
                  angle: '-90',
                  position: 'outsideTopLeft',
                  dx: -40,
                }}
                stroke={getCartesianAxisColor(isDarkMode)}
                dataKey="TotalFOB"
                tickFormatter={formatYAxis}
              />
              <Tooltip cursor={false} offset={40} content={<CustomTooltip />} />
              <Bar dataKey="TotalFOB" fill="#3F8980" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </motion.div>
    </div>
  );
}

export default BarChartImportsComponent;
