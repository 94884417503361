import './PieChartTransportCard.scss';
import React, { useRef } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import html2canvas from 'html2canvas';
import PieChartTransportComponent from '../../../Components/TECME/PieChartTransportComponent/PieChartTransportComponent';
import 'clipboard-polyfill';
import { useDarkMode } from '../../../Components/NavbarComponent/DarkModeContext';

function PieChartTransportCard() {
  const { isDarkMode } = useDarkMode();

  // Ref to the BarChartGridContainer element
  const gridContainerRef = useRef(null);

  // Handle double click function to copy graph as image
  const handleDoubleClick = () => {
    const backgroundColor = isDarkMode ? '#373C47' : '#ffffff';
    html2canvas(gridContainerRef.current, {
      backgroundColor,
      scale: 2,
    }).then((canvas) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Failed to create blob');
          return;
        }

        if (navigator.clipboard) {
          navigator.clipboard
            .write([new ClipboardItem({ 'image/png': blob })])
            .then(() => {
              const notificationElement =
                document.getElementById('notification');
              if (notificationElement) {
                notificationElement.style.display = 'block';
                setTimeout(() => {
                  notificationElement.style.display = 'none';
                }, 2000);
              }
            })
            .catch((error) => {
              console.error('Clipboard write error:', error);
            });
        } else {
          // Clipboard API not available, provide a fallback or display a message
          console.warn('Clipboard API not supported in this browser.');
        }
      }, 'image/png');
    });
  };

  return (
    <>
      <div
        className="DoubleClickImage"
        id="notification"
        style={{
          display: 'none',
          position: 'fixed',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#30333C',
          color: '#77D5D7',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 10,
        }}
      >
        Copied!
      </div>
      <Grid
        className="PieChartGridContainer"
        ref={gridContainerRef}
        onDoubleClick={handleDoubleClick}
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
      >
        <PieChartTransportComponent />
      </Grid>
    </>
  );
}

export default PieChartTransportCard;
