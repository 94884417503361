import CryptoJS from 'crypto-js';

const secretKey = 'aklsdflaksjdflkj1231l23lkasjdlakjsd919130'; // Replace with your actual secret key

export const saveUser = (user) => {
  return new Promise((resolve) => {
    const encryptedUser = CryptoJS.AES.encrypt(
      JSON.stringify(user),
      secretKey
    ).toString();
    localStorage.setItem('authUser', encryptedUser);
    resolve();
  });
};

export const getUser = () => {
  const encryptedUser = localStorage.getItem('authUser');
  if (!encryptedUser) return null;
  const bytes = CryptoJS.AES.decrypt(encryptedUser, secretKey);
  const decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedUser;
};

export const isLoggedIn = () => {
  const user = getUser();
  return user !== null;
};

export const logout = () => {
  localStorage.removeItem('authUser');
};
